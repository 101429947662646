import {
  getCarHireOffers,
  getCarHireReservation,
  getCheapestVehicle,
  getInsuranceQuoteQuery,
  getOfferImportantInformation,
  getReservationInsuranceInfo,
  getDestinationsPriceInfo,
} from 'api/carHire'
import {
  FETCH_CAR_HIRE_CHEAPEST_OFFER,
  FETCH_CAR_HIRE_OFFERS,
  FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION,
  FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE,
  FETCH_CAR_HIRE_RESERVATION,
  FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO,
  FETCH_CAR_HIRE_DESTINATION_PRICE_INFO,
} from './apiActionConstants'
import { API_CALL } from './actionConstants'
import { findCarHireListKey, getCarHireListKey } from 'components/CarHire/carHireUtils'
import { CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS, CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS } from 'constants/carHire'
import { AppAction } from './ActionTypes'
import config from 'constants/config'

const isCarHireEnabled = config.CARHIRE_ENABLED

function useCachedList(existingList?: App.CarHireList | App.CarHireCheapestOfferList | null): boolean {
  if (!existingList) return false

  const hasError = !!existingList.error
  const isFetching = !!existingList.fetching
  const isCacheValid = !!existingList.timestamp && existingList.timestamp > (Date.now() - CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS)

  return hasError || isFetching || isCacheValid
}

export function fetchCarHireOfferList(filter: App.CarHireOfferListFilters): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = getCarHireListKey(filter)

    const existingList = findCarHireListKey(state.carHire.offerLists, key)
    if (useCachedList(existingList)) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFERS,
      request: () => getCarHireOffers(filter, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchCheapestCarHireOffer(filter: App.CarHireOfferListFilters): AppAction {
  return (dispatch, getState) => {
    if (!isCarHireEnabled) { return }

    const state = getState()
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = getCarHireListKey(filter)

    const existingList = state.carHire.cheapestOfferLists[key]
    if (useCachedList(existingList)) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_CHEAPEST_OFFER,
      request: () => getCheapestVehicle(filter, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchCarHireReservation(reservationId: string, purchasingCustomerId?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const customerId = purchasingCustomerId || state.auth.account.memberId || ''

    const existingList = state.carHire.reservationInfo[reservationId]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_RESERVATION,
      key: reservationId,
      request: () => getCarHireReservation(reservationId, customerId),
    })
  }
}

export function fetchCarHireOfferImportantInfo(params: App.CarHireOfferImportantInformationParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = params.reference.id

    const existingList = state.carHire.offerImportantInformationLists[key]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION,
      request: () => getOfferImportantInformation(params, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchReservationInsuranceInfo(reservationId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const existingList = state.carHire.reservationInsuranceInfo[reservationId]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO,
      key: reservationId,
      request: () => getReservationInsuranceInfo(reservationId),
    })
  }
}

export function fetchCarHireOfferInsuranceQuote(params: App.CarHireOfferInsuranceQuoteParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = params.reference.id

    const existingList = state.carHire.offerInsuranceQuoteLists[key]

    const isFetching = !!existingList?.fetching
    const elapsedCacheTime = existingList?.timestamp ? Date.now() - existingList.timestamp : 0
    const isCacheValid = elapsedCacheTime > 0 && elapsedCacheTime <= CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS
    const cacheRemainingTime = isCacheValid ? CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS - elapsedCacheTime : 0

    // Insurance is loaded when proceeding to checkout after the availability search, so we can assume
    // that the availability search cache expiration is counting down.
    const willExpireBeforeAvailabilitySearch = cacheRemainingTime > 0 && cacheRemainingTime <= CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS

    // Ignore the insurance cache in case it's going to expire before the availability search cache.
    // This is to avoid customer purchasing a car with expired insurance (which will led to reservation failure).
    if (isFetching || (isCacheValid && !willExpireBeforeAvailabilitySearch)) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE,
      request: () => getInsuranceQuoteQuery(params, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchDestinationPriceInfo(params: App.CarHireDestinationPriceInfoParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = params.key
    const currencyCode = state.geo.currentCurrency

    const existingList = state.carHire.destinationsPriceInfoList[key]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_DESTINATION_PRICE_INFO,
      request: () => getDestinationsPriceInfo(params, currencyCode),
      key,
    })
  }
}
